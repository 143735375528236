import React, { useCallback } from 'react';
import { Grid, TextField, TextFieldProps } from '@mui/material';

type InputProps = TextFieldProps & {
  onChangeText?: (text: string) => void;
  xs?: number;
};

export function Input(props: InputProps) {
  const { onChangeText, onChange, xs = 2, ...rest } = props;
  const handleChangeText = useCallback(
    (e) => {
      onChangeText?.(e.target.value);
      onChange?.(e);
    },
    [onChange, onChangeText],
  );
  return (
    <Grid item xs={xs}>
      <TextField fullWidth {...rest} onChange={handleChangeText} />
    </Grid>
  );
}
