import React from 'react';
import { MonthKPI } from './MonthKPI';
import { useAppSelector } from '../../redux/hooks/selectors';
import { Role } from '../../lib/types';
import { ErrorPage } from '../Errors/Error';

export function Main() {
  const user = useAppSelector((state) => state.user.user);
  if (!user) {
    return <ErrorPage status={500} text={'Произошла неизвестная ошибка'} />;
  }
  switch (user.role) {
    case Role.admin:
      return <MonthKPI />;
    case Role.manager:
      return <MonthKPI />;
    case Role.developer:
      return <div />;
    case Role.client:
      return <MonthKPI />;
  }
  return <ErrorPage status={500} text={'Произошла неизвестная ошибка'} />;
}
