import moment from 'moment';
import { GSheetExportRow } from '../../types';
import { DATE_FORMAT } from '../../../resources/constants';

function splitTableByPeople(lines: Record<string, string>[]) {
  const linesByPeople: Record<string, Record<string, string>[]> = {};
  let i = 0;
  let name = '';
  while (i < lines.length) {
    const line = lines[i];
    if (!(line[''] && line[''] === line[''].toUpperCase())) {
      if (line['']) {
        name = line[''];
      }
      if (!linesByPeople[name]) {
        linesByPeople[name] = [];
      }
      linesByPeople[name].push(line);
    }
    ++i;
  }
  return linesByPeople;
}

function groupByDates(personLines: Record<string, string>[]) {
  const dates = Object.keys(personLines[0]).filter(Boolean);
  const data: Record<string, string[]> = {};
  for (let i = 0; i < dates.length; ++i) {
    data[dates[i]] = [];
    for (let j = 0; j < personLines.length; ++j) {
      data[dates[i]].push(personLines[j][dates[i]]);
    }
    data[dates[i]] = data[dates[i]].filter((proj: any) => proj && proj !== 'Выходной');
  }
  return data;
}

function prepareLine(name: string, date: string, records: string[]) {
  const lines = [];
  let sum = 0;
  let nullsCount = 0;
  for (let i = 0; i < records.length; ++i) {
    const record = records[i];
    const project = record
      .replace(/\([^)]+\)/g, '')
      .trim()
      .toLowerCase();
    if (project && project !== 'выходной') {
      const _matchTime = record.match(/\([\d|.]+\)/gi);
      const matchTime = [...(_matchTime || [])];
      const time = matchTime.length ? Number(matchTime[matchTime.length - 1].replace(/[(|)]/g, '')) : 0;
      if (time === 0) {
        nullsCount++;
      }
      sum += time;
      lines.push({ name, date, time, project });
    }
  }
  return lines.map((line) => ({ ...line, time: line.time || (8 - sum) / nullsCount }));
}

function tableToRecords(linesByPeople: Record<string, Record<string, string>[]>) {
  let records: GSheetExportRow[] = [];
  delete linesByPeople[''];
  for (const person in linesByPeople) {
    const group = groupByDates(linesByPeople[person]);
    for (const date in group) {
      const dayRecords = prepareLine(person, moment(date, 'D.M.YYYY').format(DATE_FORMAT), group[date]);
      records = [...records, ...dayRecords];
    }
  }
  return records;
}

export function processTable(lines: Record<string, string>[]): GSheetExportRow[] {
  const linesByPeople = splitTableByPeople(lines);
  const records = tableToRecords(linesByPeople);
  records.sort((a, b) => moment(a.date, 'dd.MM.yyyy').diff(moment(b.date, 'dd.MM.yyyy')));
  return records;
}
