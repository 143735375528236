import moment from 'moment/moment';

const isDevelop = process.env.NODE_ENV === 'development';
export const isDebug = localStorage.getItem('isDebug') || isDevelop;

export const DEFAULT_MONTH_PICKER_VALIES = {
  year: moment().year(),
  month: moment().month(),
};

export const MONTHS_NAMES = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export const DATE_FORMAT = 'YYYY-MM-DD';

export const { SPREADSHEET_ID, SALARY_SHEET_ID, title, showMoney } = {
  SPREADSHEET_ID: '1mhHsRre07q57vhRTGWjMBxRbdHkFDXBNLeOz7Aw9Dio',
  SALARY_SHEET_ID: '1lgbejpOZSciLeSlpDMwRTy_JaxWrhfPDXkQpO77DMz4',
  title: 'cantata',
  showMoney: true,
};
