import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { requestProjectDataAsync } from '../redux/thunkActions/dataActions';
import { PTable } from '../components/PTable';
import { useAppSelector } from '../redux/hooks/selectors';
import { Button, Grid } from '@mui/material';
import { Loader } from '../components/Loader';
import { DEFAULT_MONTH_PICKER_VALIES } from '../resources/constants';
import { ProjectsPicker } from '../components/ProjectsPicker';
import { YearPicker } from '../components/YearPicker';

const defaultState = {
  ...DEFAULT_MONTH_PICKER_VALIES,
  project: [] as string[],
};

export function ProjectKPI() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const user = useAppSelector((state) => state.user.user)!;
  const [year, setYear] = useState(defaultState.year);
  const dispatch = useDispatch();
  const [projectFilter, setProjectFilter] = useState(defaultState.project);
  const onSelect = (projects: string[]) => {
    setProjectFilter(projects);
  };
  const filtersRef = useRef({ projectFilter, year });
  filtersRef.current = { projectFilter, year };

  const [state, setState] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const loadData = useCallback(async () => {
    if (projectFilter.length > 0) {
      setIsLoading(true);
      const _project = projectFilter;
      const _year = year;
      const data = await dispatch(requestProjectDataAsync(projectFilter, year));
      if (_project === filtersRef.current.projectFilter && _year === filtersRef.current.year) {
        setState(data);
        setIsLoading(false);
      }
    } else {
      setState([]);
    }
  }, [dispatch, projectFilter, year]);
  useEffect(() => {
    loadData();
  }, [loadData]);

  const resetState = () => {
    setProjectFilter(defaultState.project);
    setYear(defaultState.year);
  };
  return (
    <Grid container padding={2} direction={'column'} spacing={4}>
      <Loader isLoading={isLoading} />
      <Grid container item spacing={2} direction={'row'} alignItems={'center'}>
        <ProjectsPicker xs={4} onSelect={onSelect} value={projectFilter} />
        <YearPicker selected={year} onChange={setYear} />
        <Grid item>
          <Button variant='contained' color={'error'} onClick={resetState}>
            Сброс
          </Button>
        </Grid>
      </Grid>
      <Grid item>{!isLoading && <PTable data={state} role={user.role} />}</Grid>
    </Grid>
  );
}
