import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { ru } from 'date-fns/locale';
import { requestLastSyncDateAsync, sendDataToAirtable, syncDataAsync } from '../redux/thunkActions/dataActions';
import { useAppSelector } from '../redux/hooks/selectors';
import { recordToArray } from '../lib/utils';
import { useDispatch } from 'react-redux';
import { Button, Grid, Paper } from '@mui/material';
import { useAwaitCallback } from '../hooks';
import { Loader } from '../components/Loader';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CollapsibleTable, { TableConfig } from '../components/CollapsibleTable';

export function AirtableSync() {
  const dispatch = useDispatch();
  const lastSyncDate = useAppSelector((state) => state.data.lastSyncDate);
  const [lastCheckedDate, setLastCheckedDate] = useState(new Date());
  const onChangeLastCheckedDate = useCallback((date: Date | null) => {
    if (date) {
      setLastCheckedDate(date);
    }
  }, []);
  const [syncData, isLoadingGTable] = useAwaitCallback(async () => {
    await dispatch(syncDataAsync(lastCheckedDate));
  }, [dispatch, lastCheckedDate]);

  const dataToSync = useAppSelector((state) => state.data.dataToSync);
  const table = useMemo(() => {
    if (!dataToSync) {
      return [];
    }
    const obj: Record<string, number> = {};
    for (let i = 0; i < dataToSync.length; i++) {
      if (!obj[dataToSync[i].name]) {
        obj[dataToSync[i].name] = 0;
      }
      obj[dataToSync[i].name] += dataToSync[i].time;
    }
    return recordToArray(obj);
  }, [dataToSync]);
  const sendToAirtable = useCallback(async () => {
    await dispatch(sendDataToAirtable());
    dispatch(requestLastSyncDateAsync());
  }, [dispatch]);

  const tableConfig: TableConfig = {
    head: [{ label: 'ФИО' }, { label: 'Часы' }],
    rows: table.map((item) => ({
      row: [item.title, item.value],
    })),
  };
  return (
    <Grid container padding={2} spacing={4}>
      <Loader isLoading={isLoadingGTable} />
      <Grid container item spacing={2} direction={'row'} alignItems={'center'}>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
            <DatePicker
              onChange={onChangeLastCheckedDate}
              value={lastCheckedDate}
              maxDate={new Date()}
              minDate={moment(lastSyncDate).toDate()}
              format='dd.MM.yy'
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <Button variant='contained' onClick={syncData}>
            Проверить
          </Button>
        </Grid>
        <Grid item>
          <Button variant='contained' onClick={sendToAirtable} color={'error'}>
            Синхронизировать
          </Button>
        </Grid>
      </Grid>
      <Grid item container>
        {table.length ? (
          <CollapsibleTable component={Paper} config={tableConfig} isCollapsible={false} isSmall />
        ) : null}
      </Grid>
    </Grid>
  );
}
