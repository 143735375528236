import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';

export interface DefaultComponentProps {
  className?: string;
  children?: React.ReactNode;
}

export interface IProjectBody {
  detail: Record<string, IPersonBody>;
  totalTime: number;
  limit: number;
  revenue: number;
  ROI: number;
  cost: number;
  pnl: number;
}

export interface IPersonBody {
  detail: Record<string, number>[];
  _lineOfBusiness: string;
  totalTime: number;
}

export interface IOvertimePersonBody {
  detail: Record<string, Record<string, number>>[];
  _lineOfBusiness: string;
  totalTime: number;
}

export interface IProject {
  [key: string]: IProjectBody;
}

export interface IRecord {
  createdTime: string;
  fields: {
    time: number;
    date: string;
    _project: string;
    _person: string;
    limit: string;
  };
  id: string;
}

export interface AppState {
  initialized: boolean;
}

export type PersonData = NamedValue<IPersonBody>;
export type ProjectData = NamedValue<IProjectBody>;

export enum Role {
  admin = 'admin',
  manager = 'manager',
  developer = 'developer',
  client = 'client',
}

export interface IUser {
  id: string;
  username: string;
  role: Role;
}

export interface UserState {
  user?: IUser;
  token: string;
}

export type AppDispatch = ThunkDispatch<GlobalState, any, AnyAction>;

export interface DataState {
  isLoading: boolean;
  projectData: ProjectData[];
  personData: PersonData[];
  lastSyncDate: string;
  dataToSync: GSheetExportRow[] | undefined;
}

export interface GlobalState {
  app: AppState;
  data: DataState;
  user: UserState;
}

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, GlobalState, unknown, Action<string>>;

export type AirtableFields = {
  _project: string;
  _person: string;
  _lineOfBusiness: string;
  date: string;
  time: number;
  limit: number[];
  revenue: number;
  ROI: number[];
  cost: number;
  month: number;
  pnl: number;
  overtime: boolean;
};

export type GSheetExportRow = {
  name: string;
  date: string;
  time: number;
  project: string;
};

export type AirtableItem = {
  fields: AirtableFields;
};

export interface NamedValue<T> {
  title: string;
  value: T;
}

export interface ApiStatisticsResponse {
  records: AirtableItem[];
  offset: string;
}

export type AirtableFieldsType = keyof Exclude<AirtableFields, 'ROI'>;
