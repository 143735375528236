import { Main } from '../pages/Main';
import { Overtime } from '../pages/Overtime';
import { AirtableSync } from '../pages/AirtableSync';
import { SalaryPage } from '../pages/SalaryPage';
import { ProjectKPI } from '../pages/ProjectKPI';

export const routes = {
  index: {
    path: '/',
    private: true,
    admin: false,
    exact: true,
    component: Main,
    label: 'Месячный отчет',
  },
  projects: {
    admin: false,
    path: '/projects',
    component: ProjectKPI,
    label: 'Экономика проектов',
    exact: true,
  },
  overtime: {
    admin: false,
    path: '/overtime',
    component: Overtime,
    label: 'Сотрудники',
    exact: true,
  },
  airtable: {
    admin: true,
    path: '/airtable',
    component: AirtableSync,
    label: 'Выгрузка часов',
    exact: true,
  },
  salary: {
    admin: true,
    path: '/salary',
    component: SalaryPage,
    label: 'Выгрузка ЗП',
    exact: true,
  },
};
