import { GApi } from './types';
import { getColumns } from './tableActions';
import { getDayNumber } from '../index';
import { DATE_FORMAT } from '../../../resources/constants';
import moment from 'moment';
import { processTable } from './processTable';

const CLIENT_ID = '643034037969-o2qmrgjq9hrj931i1bdkpls7ti1cp7q9.apps.googleusercontent.com';
const API_KEY = 'AIzaSyCopzmL9nXgCqbmNSSS66jCTv10fLbXmZk';

const DISCOVERY_DOCS = ['https://sheets.googleapis.com/$discovery/rest?version=v4'];

const SCOPES = 'https://www.googleapis.com/auth/spreadsheets.readonly';

let gapi: GApi;

export function googleApiAuth() {
  // @ts-ignore
  gapi = window.gapi;
  return new Promise<void>((resolve, reject) => {
    gapi.load('client:auth2', () => {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        })
        .then(async () => {
          const signed = gapi.auth2.getAuthInstance().isSignedIn.get();
          if (!signed) {
            gapi.auth2.getAuthInstance().isSignedIn.listen((status: boolean) => {
              if (status) {
                resolve();
              }
            });
            await gapi.auth2.getAuthInstance().signIn();
          } else {
            resolve();
          }
        })
        .catch(reject);
    });
  });
}

function toDate(str: string): Date {
  const [y, m, d] = str.split('-').map(Number);
  const date = new Date();
  date.setFullYear(y, m - 1, d);
  return date;
}

const alphabet = 'abcdefghijklmnopqrstuvwxyz';

function indexToSymbols(index: number) {
  let res = '';
  let i = index;
  while (i >= 0) {
    const v = i % alphabet.length;
    i = (i - v) / alphabet.length - 1;
    res = alphabet[v] + res;
  }
  return res;
}

function transformTable(rows: string[][]): Record<string, string>[] {
  const res = [];
  for (let i = 1; i < rows.length; i++) {
    const row: Record<string, string> = {};
    res.push(row);
    for (let j = 0; j < rows[0].length; j++) {
      row[rows[0][j] || ''] = (rows[i][j] || '').replace(',', '.');
    }
  }
  return res;
}

export async function requestGTableTime(lastSyncDate: string, lastCorrectDate: string) {
  const date = moment(lastSyncDate, DATE_FORMAT);
  const lastDate = moment(lastCorrectDate, DATE_FORMAT);
  const range = lastDate.diff(date, 'd');
  const first: string = moment((await getColumns('b'))[0][0], 'D.M.YYYY').format(DATE_FORMAT);
  const tableStartDayNumber = getDayNumber(toDate(first));
  const dayNumber = getDayNumber(date.toDate()) + 1;
  const start = ((dayNumber - tableStartDayNumber + 365) % 365) + 1;
  const startColumn = indexToSymbols(start);
  const end = indexToSymbols(start + range - 1);
  const names: string[][] = await getColumns('a');
  const time: string[][] = await getColumns(startColumn, end);
  const res: string[][] = [];
  for (let i = 0; i < Math.max(names.length, time.length); i++) {
    res.push([]);
    const row = res[i];
    row.push((names[i] || [])[0], ...(time[i] || []));
  }
  const r = transformTable(res);
  return processTable(r);
}
