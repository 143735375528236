import React, { useState } from 'react';
import { Input } from '../../components/Input';
import { useAppDispatch, useAwaitCallback } from '../../hooks';
import { loginAsync } from '../../redux/thunkActions/userActions';
import { Grid, Card, Button } from '@mui/material';
import { Loader } from '../../components/Loader';

export function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useAppDispatch();
  const [onSubmit, isLoading] = useAwaitCallback(async () => {
    await dispatch(loginAsync(username, password));
  }, [dispatch, password, username]);
  return (
    <Grid container alignItems={'center'} justifyContent={'center'}>
      <Card>
        <Loader isLoading={isLoading} />
        <Grid padding={'16px'} container spacing={2} direction={'column'}>
          <Input label='Имя пользователя' onChangeText={setUsername} />

          <Input label='Пароль' onChangeText={setPassword} type={'password'} />

          <Grid item xs={2}>
            <Button disabled={isLoading} type={'submit'} variant={'contained'} onClick={onSubmit}>
              Войти
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
