import { AirtableFields, AirtableFieldsType, IPersonBody, IProject } from '../lib/types';
import { groupBy } from '../lib/utils';

const PROJECT = '';

const apiUrl = '/api/airtable';

export function processAirtableResponse(
  response: { records: AirtableItem[] },
  first: AirtableFieldsType = '_project',
  second: AirtableFieldsType = '_person',
  ejectOvertime = true,
) {
  const res = groupBy(
    response.records.map((i) => ({
      ...i,
      fields: {
        ...i.fields,
        [first]: ejectOvertime && i.fields.overtime ? `${i.fields[first]} - овертайм` : i.fields[first],
      },
    })),
    first,
  );
  const result: IProject = {};
  for (const project in res) {
    result[project] = {
      totalTime: 0,
      detail: {},
      limit: 0,
      revenue: 0,
      cost: 0,
      pnl: 0,
      ROI: 0,
    };
    const projectResult = result[project];
    const fullNameGroup = groupBy(res[project].records, second);
    for (const person in fullNameGroup) {
      projectResult.detail[person] = {
        totalTime: fullNameGroup[person].records.reduce((total, cur) => total + cur.fields.time, 0),
        detail: fullNameGroup[person].records.map((record) => ({ [record.fields.date]: record.fields.time })),
        _lineOfBusiness: fullNameGroup[person].records[0]?.fields._lineOfBusiness,
      };
      projectResult.totalTime += projectResult.detail[person].totalTime;
      projectResult.limit = fullNameGroup[person].records[0]?.fields.limit?.[0] || 0;

      projectResult.pnl += fullNameGroup[person].records.reduce((total, cur) => total + cur.fields.pnl, 0);
      projectResult.cost += fullNameGroup[person].records.reduce((total, cur) => total + cur.fields.cost, 0);
      projectResult.revenue += fullNameGroup[person].records.reduce((total, cur) => total + cur.fields.revenue, 0);
      projectResult.ROI = fullNameGroup[person].records[0].fields.ROI[0];
    }
  }
  return result;
}

type AirtableItem = {
  fields: AirtableFields;
};
export async function loadAll(dateFrom: Date, dateTo: Date) {
  const DATE_FROM = new Date(new Date(dateFrom).setDate(new Date(dateFrom).getDate() - 1));
  const FORMULA_DATE_FROM = `${DATE_FROM.getFullYear()}-${DATE_FROM.getMonth() + 1}-${DATE_FROM.getDate()}`;

  const DATE_TO = new Date(new Date(dateTo).setDate(new Date(dateTo).getDate() + 1));
  const FORMULA_DATE_TO = `${DATE_TO.getFullYear()}-${DATE_TO.getMonth() + 1}-${DATE_TO.getDate()}`;

  // eslint-disable-next-line max-len
  const formula = `filterByFormula=AND(AND(FIND("${PROJECT}", {_Project}), IS_AFTER({Date}, "${FORMULA_DATE_FROM}"), IS_BEFORE({Date}, "${FORMULA_DATE_TO}")))`;

  const records = [];
  let offset = '';
  do {
    const offsetUrl = offset ? `&offset=${offset}` : '';
    const response = await fetch(`${apiUrl}?${formula}${offsetUrl}`, {
      method: 'GET',
      redirect: 'follow',
    });
    const json: { offset: string; records: AirtableItem[] } = await response.json();
    offset = json.offset;
    records.push(...json.records);
  } while (offset);
  const _byFullName = groupBy(records, '_person');
  const byFullName: { [name: string]: IPersonBody } = {};
  const byProject = processAirtableResponse({ records });
  Object.keys(_byFullName).forEach((name) => {
    byFullName[name] = {
      totalTime: _byFullName[name].records.reduce((total, cur) => total + cur.fields.time, 0),
      detail: _byFullName[name].records.map((record) => ({ [record.fields.date]: record.fields.time })),
      _lineOfBusiness: '',
    };
  });
  return { byFullName, byProject };
}
