import React, { useMemo } from 'react';
import { matchPath, Switch, useLocation } from 'react-router-dom';
import { routes } from './resources/routes';
import { useUser } from './redux/selectors/useUser';
import { Redirect, Route } from 'react-router';
import { NotFoundPage } from './pages/Errors/NotFoundPage';
import { LoginPage } from './pages/LoginPage';
import { Page } from './components/Page';

export function Router() {
  const user = useUser();
  const location = useLocation();

  const isFoundRoute = useMemo(() => {
    const keys = Object.keys(routes);

    for (let i = 0; i < keys.length; i++) {
      const route = routes[keys[i]];
      const match = matchPath(location.pathname, route);

      if (match) return true;
    }
  }, [location.pathname]);

  const pages = Object.entries(routes).map(([routeName, config]) => {
    const render = () => {
      const isUnavailable = false;
      if (isUnavailable) {
        return <Redirect key={routeName} to={routes.index.path} />;
      }
      const Component = config.component;
      return (
        <Page>
          <Component />
        </Page>
      );
    };
    return <Route key={config.path} exact={config.exact} path={config.path} render={render}></Route>;
  });
  if (!isFoundRoute) {
    return <NotFoundPage />;
  }
  if (!user) {
    return <Route component={LoginPage} />;
  }
  return <Switch>{pages}</Switch>;
}
