import React from 'react';
import { Provider } from 'react-redux';
import { store, persistor } from './reduxConfig';
import { PersistGate } from 'redux-persist/integration/react';

interface AppProviderProps {}
export class AppProvider extends React.Component<AppProviderProps> {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {this.props.children}
        </PersistGate>
      </Provider>
    );
  }
}
