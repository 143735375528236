import React, { useCallback, useEffect, useMemo } from 'react';
import { useAppSelector } from '../../redux/hooks/selectors';
import moment from 'moment';
import { DATE_FORMAT } from '../../resources/constants';
import { requestLastSyncDateAsync } from '../../redux/thunkActions/dataActions';
import { useDispatch } from 'react-redux';
import { useAppDispatch } from '../../hooks';
import { logoutAsync } from '../../redux/thunkActions/userActions';
import { Role } from '../../lib/types';
import MenuIcon from '@mui/icons-material/Menu';

import { getTitle } from '../../lib/utils';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import { routes } from '../../resources/routes';

const drawerWidth = 240;

export function Header() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const isAdmin = user?.role === Role.admin;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const logout = useCallback(() => {
    dispatch(logoutAsync());
  }, [dispatch]);
  if (!user) {
    return <div />;
  }
  const routeNames = Object.keys(routes);
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant='h6' sx={{ my: 2 }}>
        {getTitle()}
      </Typography>
      <Divider />
      <List>
        {routeNames.map((item) =>
          !routes[item].admin || isAdmin ? (
            <ListItem key={item} disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }} href={routes[item].path}>
                <ListItemText primary={routes[item].label} />
              </ListItemButton>
            </ListItem>
          ) : null,
        )}
        <Button onClick={logout} variant={'contained'} color={'error'}>
          Выйти
        </Button>
      </List>
    </Box>
  );
  const container = window !== undefined ? () => window.document.body : undefined;
  return (
    <>
      <AppBar>
        <Toolbar>
          <IconButton color='inherit' aria-label='open drawer' edge='start' onClick={handleDrawerToggle} sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Typography
            variant='h6'
            component='div'
            sx={{
              flexGrow: 1,
            }}>
            {getTitle()}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: 'block',
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}>
          {drawer}
        </Drawer>
      </nav>
    </>
  );
}

export function Footer() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(requestLastSyncDateAsync());
  }, [dispatch]);
  const lastSyncDate = useAppSelector((state) => state.data.lastSyncDate);
  const date = useMemo(
    () => (lastSyncDate ? moment(lastSyncDate, DATE_FORMAT).format('DD.MM.YYYY') : undefined),
    [lastSyncDate],
  );
  return (
    <AppBar position='fixed' sx={{ top: 'auto', bottom: 0 }}>
      <Toolbar>
        <Typography>Последняя запись в Airtable: {date || <CircularProgress size={16} />}</Typography>
      </Toolbar>
    </AppBar>
  );
}
