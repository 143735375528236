import { createAction } from '@reduxjs/toolkit';
import { GSheetExportRow, IUser, PersonData, ProjectData } from '../lib/types';

export const setInitialized = createAction<void>('setInitialized');
export const reset = createAction<void>('reset');

export const setPersonData = createAction<PersonData[]>('setPersonData');
export const setProjectData = createAction<ProjectData[]>('setProjectData');
export const setIsLoading = createAction<boolean>('setIsLoading');
export const setLastSyncDate = createAction<string>('setLastSyncDate');
export const setDataToSync = createAction<undefined | GSheetExportRow[]>('setDataToSync');

export const setUserInfo = createAction<IUser>('setUserInfo');
export const setAuth = createAction<{ user?: IUser; token: string }>('setAuth');
